import React, { Component } from 'react'
import { Page, Text, View, Canvas, Document, Image, Font, Link } from '@react-pdf/renderer'
import styles from '../ProductPDF/product-pdf-styles'
import pdfImages from '../ProductPDF/product-pdf-images'
import cloneDeep from 'lodash.clonedeep'
const isBrowser = typeof window !== `undefined`

class ProjectPDF extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps) {

  }

  drawLine = (painter, w, h) => {
    painter.strokeColor('rgba(0,0,0,0.8)')
    painter.moveTo(0,0).lineTo(556,0).stroke()
  }

  drawSquare = (painter, w, h) => {
    painter.strokeColor('rgba(0,0,0,0.8)')
    painter.lineWidth(.5)
    painter.moveTo(0,0).lineTo(302,0).stroke()
    painter.moveTo(302,0).lineTo(302,202).stroke()
    painter.moveTo(302,202).lineTo(0,202).stroke()
    painter.moveTo(0,202).lineTo(0,0).stroke()
  }

  render() {
    Font.register({ family: 'Helvetica Neue', src: '/fonts/HelveticaNeueLTStd-LtCn.woff' })
    Font.register({ family: 'Helvetica Neue Bold', src: '/fonts/HelveticaNeueLTStd-MdCn.woff' })
    Font.register({ family: 'Helvetica Neue Heavy', src: '/fonts/HelveticaNeue-HeavyExt.ttf' })
    let pages = []
    let header = <View fixed style={styles.headerStyle}>
        <Image src={pdfImages.configuratorLogo2.src} style={pdfImages.configuratorLogo2.style} />
        <Canvas paint={this.drawLine} style={styles.headerLineStyle} />
        <Text fixed style={styles.projectTitle}>
          SPECIFICATION SHEET
        </Text>
      </View>
    let date = new Date()
    let revDate = ('0'+(date.getMonth() +1)).slice(-2) +'-'+('0'+date.getDate()).slice(-2)+'-'+date.getFullYear().toString().substr(-2)
    let printDate = ('0'+(date.getMonth() +1)).slice(-2) +'/'+('0'+date.getDate()).slice(-2)+'/'+date.getFullYear().toString().substr(-2)
    let smartCode = null
    let footer = <View fixed style={styles.footerStyle}>
        <Text fixed style={styles.footerTextStyle}>
        T 800.451.0410 | www.forms-surfaces.com
        </Text>
        <Canvas fixed paint={this.drawLine} style={styles.footerLineStyle} />
        <Text fixed style={styles.copyRight}>
          &copy; {date.getFullYear()} Forms+Surfaces&reg; | All dimensions are nominal. Specifications and pricing subject to change without notice. or the most current version of this document, please refer to our website at www.forms-surfaces.com.
        </Text>
        <Text style={styles.pageRevisions} fixed render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages} | Rev. ${revDate}`
        )} />
        <Text fixed style={styles.pageInfo}>
        Project ID: <Link style={{
          color: 'rgba(0,0,0,0.8)',
          textDecoration: 'none'
        }} src={process.env.GATSBY_IFRAME_URL + '#project/' + this.props.project._id} target="_blank"><Text>{this.props.project._id}</Text></Link>
        </Text>
      </View>

    let projectText = []
    let location = []
    if(this.props.project.city){
      location.push(this.props.project.city)
    }
    if(this.props.project.state){
      location.push(this.props.project.state)
    }
    if(this.props.project.zip){
      location.push(this.props.project.zip)
    }
    if(this.props.project.country){
      location.push(this.props.project.country)
    }
    projectText.push(
      <Text wrap={true} key={'project-text-1'} style={styles.bodyTextStyle}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>PROJECT NAME:  </Text>
      {this.props.project.name}
      </Text>)
    projectText.push(
      <Text wrap={true} key={'project-text-2'} style={styles.bodyTextStyle}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>DATE:  </Text>
      {printDate}
      </Text>)
    projectText.push(
      <Text wrap={true} key={'project-text-3'} style={styles.bodyTextStyle}>
      <Text style={{fontFamily: 'Helvetica-Bold'}}>Location:  </Text>
      {location.join(', ')}
      </Text>)

    let designGrid = this.props.project.designs.map((design, i) => {
      let imageSrc = process.env.GATSBY_IMAGE_URL+design.imagePath
      let image = <Image src={{ uri: imageSrc, method: 'GET', headers: { "Cache-Control": "no-cache" }, body: '' }} style={styles.designImage} />
      return (
        <View wrap={false} fixed={false} style={styles.projectDesignListItem} key={'design-grid'+i}>
          <View wrap={true} style={styles.projectDesignListItemText}>
            <Text wrap={true} style={styles.projectDesignText}><Text style={{fontFamily: 'Helvetica-Bold'}}>{design.name}</Text></Text>
            <Text wrap={true} style={styles.projectDesignText}>Quantity:  {design.quantity}</Text>
          </View>
          {image}
        </View>
      )
    })
    pages.push(
    <Page size="LETTER" wrap={true} style={styles.bodyStyle} key={"design-configuration-overview-2"}>
        {header}
        <View style={styles.projectDescriptionStyle}>
        {projectText}
        </View>
        <Text style={styles.bodyTextStyle}>
        <Text style={styles.bodyTextLabel}>PRODUCTS</Text>
        </Text>
        <View wrap={true} fixed={false} style={styles.projectDesignList}>
        {designGrid}
        </View>
        {footer}
    </Page>)

    this.props.project.designs.forEach((design, i) => {
      let designNotes = null
      let descriptionBlocks = []
      let productTitle = null
      let productModel = null
      let weight = null
      let quantity = null
      if(design?.smartDescription){
        let metaDescription = cloneDeep(design.smartDescription)
        let hardware = design.pricing.lineItems.filter((item)=>{
          return item.hardwareKit
        })
        hardware.forEach((item, i) => {
          let findDesc = metaDescription.find((desc)=>{
            return desc.label === item?.description
          })
          if(!findDesc){
            metaDescription.push({
              label: item?.description,
              description: item.shortDescription + (item.quantity ?  ' - Qty ' + item.quantity : '')
            })
          }
        })
        let model = ''
        if(design.pricing?.lineItems?.length){
          model = design.pricing.lineItems[0].model
        }
        productTitle = <Text wrap={true} style={styles.productTitle}>
            {metaDescription[0]?.description}
          </Text>

        productModel = <Text wrap={true} style={styles.productTitle}>
            {model}
          </Text>

        for(i = 1; i < metaDescription.length; i++){
          let description = metaDescription[i]?.description.split(/\\n/).join('\n')
          if(metaDescription[i]?.label){
            let label = metaDescription[i].label
            if(metaDescription[i].customText){
              description += '\n'+metaDescription[i].customText
            }
            descriptionBlocks.push({
              label: label,
              description: description
            })
          }
        }

        weight = <Text wrap={true} style={styles.bodyTextStyle}>
          <Text style={{fontFamily: 'Helvetica-Bold'}}>Weight:  </Text>
          {(design.pricing.weightTotalPrint === '0.00 lbs.' ? 'Weight Not Available' : design.pricing.weightTotalPrint)}
          </Text>

        quantity = <Text wrap={true} style={styles.bodyTextStyle}>
          <Text style={{fontFamily: 'Helvetica-Bold'}}>Quantity:  </Text>
          {design.quantity}
          </Text>

        if(design.notes){
          designNotes = <View style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Text style={styles.bodyTextStyle}>
            <Text style={{fontFamily: 'Helvetica-Bold'}}>Notes:  </Text>
            {design.notes}
            </Text>
          </View>

        }
      }
      smartCode = design.smartCode
      let image = null
      let imageBorder = null
      if(design.imagePath){
        let imageSrc = process.env.GATSBY_IMAGE_URL+design.imagePath
        imageBorder = <Canvas paint={this.drawSquare} style={styles.imageBorder} />
        image = <Image src={{ uri: imageSrc, method: 'GET', headers: { "Cache-Control": "no-cache" }, body: '' }} style={styles.productImage} />
      }
      pages.push(
      <Page size="LETTER" wrap={true} style={styles.bodyStyle} key={"design-configuration-overview-2"+i}>
        {header}
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'space-between',
          gap: '10',
          marginBottom: '20'
        }}>
          <View wrap={true} style={styles.productDescriptionStyle}>
            {productTitle}
            {productModel}
            {quantity}
            {weight}
            <Text wrap={true} style={styles.bodyTextStyle}>
            {descriptionBlocks.map((item, i)=>{
              return(
                <Text key={i} wrap={false}>
                <Text style={{fontFamily: 'Helvetica-Bold'}}>{item.label}:  </Text>
                {item.description}{'\n'}</Text>
              )
            })}
            </Text>
          </View>
          <View style={{
            height: '100%'
          }}>
          {imageBorder}
          {image}
          </View>
        </View>
        {designNotes &&
          <View wrap={true} style={{width: '100%'}}>
          {designNotes}
          </View>
        }
        {footer}
        <Text wrap={true} style={styles.projectSmartCode}>
          Smart Code: {smartCode}
        </Text>
      </Page>)
    })



    let renderPages = pages.map(function(object){
      return object
    })

    let outputDocument = <Document>
      {renderPages}
    </Document>
    if(!isBrowser){
      outputDocument = null
    }

    return outputDocument
  }
}

export default ProjectPDF
