import React, { Component } from 'react'
import Layout from '../layout-mobile'
import ToolPanel from '../ToolPanel'
import {
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIconWhite from '../../styles/img/download-icon-white.svg'
import CameraIcon from '../../styles/img/camera-icon-white.svg'
import AuthPanel from '../Auth/auth-drawer'
import LoginIcon from '@mui/icons-material/Login'
import FullScreenButton from './full-screen-button'
import Viewer from '../ProductViewer/viewer'
import LoadingBox from '../loading-box'
import LightToggle from './light-toggle'
import ProductPDF from '../ProductPDF'
import ProductsBrowser from '../ProductsBrowser'
import ProjectBrowser from '../Favorites/project-browser'
import ProjectFormNew from '../ProductViewer/project-form-new'
import QRCode from 'qrcode.react'
import { pdf } from '@react-pdf/renderer'
import {isMobile} from 'react-device-detect'
import {
  formatPricing,
  mergeHardwareKitsIntoPricing
} from '../../utils/pricing'
import { isLoggedIn } from '../../utils/auth'
import { 
  createDesign,
  getDistinctCustomText } from '../../utils/design'
import {
  buildSayduckSelections,
  initSelections,
  findSelectedLimits,
  getSelectedDimensions,
  getSmartCodeAndDescription,
  selectionsFromProductObject,
  treeSelector, } from '../../utils/selectors'
import {
  getAllProjects
} from '../../utils/project'
import {
  resetCamera,
  takePhoto
} from '../../utils/sayduck'
import {
  navigate } from '../../utils/misc'
import {
  getUserInfo
} from '../../utils/user'
import { isBrowser } from '../../utils/misc'
import ArIcon from '../../styles/img/ar-icon.svg'
import { AppRegistrationOutlined } from '@mui/icons-material'
var cloneDeep = require('lodash.clonedeep')
const Timeout = 20000
let api;

class View extends Component {
  constructor(props) {
    super(props)
    this.productViewer=React.createRef()
    this.backToProjectButton=React.createRef()
    this.saveButton=React.createRef()
    let product = this?.props?.context?.product
    let productDefault = []
    if(product.default_configuration){
      productDefault = product.default_configuration.split('-')
    }
    product = initSelections(product, productDefault)
    let selectors = selectionsFromProductObject(product)
    let limits = findSelectedLimits(selectors)
    let dimensionOverrides = []
    let descriptionOverrides = []
    let metaData = []
    if(product){
      dimensionOverrides = {
        overrides: (product.dimension_overrides ? product.dimension_overrides : [] ),
        overrideIndex: (product.dimension_override_index ? product.dimension_override_index : 1 )
      }
      let descriptionOverrides = []
      if(product.description_overrides){
        descriptionOverrides = product.description_overrides
      }
      let dimensions = getSelectedDimensions(selectors, dimensionOverrides)
      metaData = getSmartCodeAndDescription(selectors, dimensions, descriptionOverrides)
    }
    let lightsOffTriggers = []
    if(product?.lights_off_triggers){
      if(product.lights_off_triggers.length > 0){
        lightsOffTriggers = product.lights_off_triggers
      }
    }
    product = mergeHardwareKitsIntoPricing(product, this.props.context.hardwareKits)
    let design = {
      _id: '',
      smartCode: metaData.smartCode.join('-'),
      name: product.product_name,
      file: '',
      productClass: product.product_class,
      configuratorId: product.configurator_id,
      dimensions: metaData.dimensions,
      quantity: 1,
      notes: ''
    }
    design.pricing = formatPricing(metaData, product.pricing_options, design.quantity)
    let details = buildSayduckSelections(selectors)
    delete details[product.configurator_id]
    if(product?.uses_light){
      setTimeout(function(){
        if(isBrowser){
          window.dispatchEvent(
            new CustomEvent(
              'sayduck.viewer.actions.toggleSwitchableLights')
          )
        }
      },2000)
    }
    if(isBrowser){
      window.document.addEventListener("sayduck.viewer.gltf.loaded", this.renderComplete, false)
    }
    this.checkTimeout()
    this.state = {
      loggedIn: null,
      design: design,
      customTextVals: [],
      details: details,
      descriptionOverrides: descriptionOverrides,
      dimensionOverrides: dimensionOverrides,
      lightsOffTriggers: lightsOffTriggers,
      prevDesign: null,
      errMsg: '',
      timeoutMessage: false,
      ArMsg: false,
      product: product,
      selectors: selectors,
      limits: limits,
      panel1: true,
      panel2: true,
      metaData: metaData,
      successMsg: '',
      loading: true,
      userInfo: null,
      myProjects: [],
      showProjectBrowser: false,
      showQRCode: false,
      viewerHeight: 'calc(50vh)',
      toolPosition: 'calc(50vh)',
      isFullScreen: false,
      browserOpen: false,
      showAuthPanel: false,
      showNewProject: false,
      showAddToProjectSuccess: false,
      defaultAuthView: 'login',
      qrText: '',
      viewerLoaded: false,
      lightsOn: false
    }
    if(isBrowser){
      window.addEventListener('sayduck.api-ready', (event) => {
        console.log('sayduck api ready')
        this.renderComplete()
        api = event.detail.instance
        setTimeout(()=>{
          if(api){
            resetCamera(api);
          }
        },500)
      })
    }
  }

  setLoggedIn = (loggedIn) => {
    this.setState({
      loggedIn: loggedIn
    })
  }

  toggleMenu = (expand) => {
    this.setState({
      expandMenu: expand
    })
  }

  componentDidMount(){
    isLoggedIn()
    .then(user=>{
      this.handleGetUserInfo()
      this.getProjects()
    })
    .catch(err=>{
      //catch error and do nothing extra
    })
  }

  checkTimeout = () => {
    setTimeout(() => {
      if(!this.state.viewerLoaded){
        this.setState({
          timeoutMessage: true
        })
      }
    }, Timeout);
  }

  renderComplete = async (e) => {
    console.log('render complete')
    this.setState({
      viewerLoaded: true
    })
    this.handleSetLoading(false)
    if(isBrowser){
      window.document.removeEventListener("sayduck.viewer.gltf.loaded", this.renderComplete)
    }
  }

  getProjects = () => {
    getAllProjects()
    .then(function(data){
      // this.handleSetLoading(false)
      data = data.filter((object)=>{
        return !object.locked
      })
      this.setState({
        myProjects: data
      })
    }.bind(this))
    .catch(e => {
      this.handleSetLoading(false)
    })
  }

  downloadPDF = async () => {
    let fileName = this.state?.product?.product_name.toLowerCase().replaceAll(' ','-')
    if(isBrowser){
      this.handleSetLoading(true)
      let photo = await takePhoto(api)
      
      setTimeout(function(){
        const productPDF = <ProductPDF
          pricingOptions={this.state.product.pricing_options}
          productMeta={this.state.metaData}
          notes={this.state.design.notes}
          image={photo.dataUrl}
          quantity={this.state.design.quantity}
          />
        pdf(productPDF).toBlob()
        .then(function(blob){
          this.handleSetLoading(false)
          var pdfURL = window.URL.createObjectURL(blob)
          let tempLink = document.createElement('a')
          tempLink.href = pdfURL
          tempLink.setAttribute('download', fileName + '.pdf')
          tempLink.click()
        }.bind(this))
      }.bind(this))
    }
  }

  handleUpdateNotes = (notes) => {
    let design = {...this.state.design}
    design.notes = notes
    this.setState({
      design: design
    })
  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  handleCloseArMessage = () => {
    this.setState({
      ArMsg: false
    })
  }

  handleCloseTimeoutMessage = () => {
    this.setState({timeoutMessage: ''})
  }

  updateLoggedIn = (userData) => {
    this.setState({
      loggedIn: userData
    })
  }

  toggleProducts = () => {
    this.setState({
      showProductsList: !this.state.showProductsList
    })
  }

  toggleLights = (lightsOn) => {
    if (isBrowser) {
      setTimeout(function(){
        let details = {...this.state.details}
        let shadowSelectors = null
        if(this.state.product.shadow_id){
          shadowSelectors  = {
            id: this.state.product.shadow_id,
            off_id: this.state.product?.shadow_off_id,
            shadows: this.state.product.shadows
          }
        }
        if(shadowSelectors && !lightsOn){
          let shadow_id = shadowSelectors.id
          details[shadow_id] = shadowSelectors.off_id
        }else if(shadowSelectors && lightsOn){
          let shadow_id = shadowSelectors.id
          details[shadow_id] = shadowSelectors.shadows[0].configurator_id
        }
        this.setState({
          lightsOn: lightsOn,
          details: details
        }, function(){
          if(isBrowser){
            for (var key in details) {
              try{
                api.configurator.setActiveByUuid(details[key])
              }catch(err){
                console.log(err)
              }
            }
          }
        })
      }.bind(this),2000)
    }
  }

  handleGetUserInfo = (callbackFunction) => {
    this.setState({
      loading: true
    })
    let userInfo = null
    getUserInfo()
    .then(info=>{
      userInfo = info
      return getDistinctCustomText()
    })
    .then(customText=>{
      this.setState({
        userInfo: userInfo,
        loading: false,
        customTextVals: customText
      }, function(){
        if(callbackFunction){
          callbackFunction()
        }
      })
    })
    .catch(err=>{
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    })
  }


  updateData = (object, previousSelection) => {
    let selectors = cloneDeep(this.state.selectors)
    let dimensionOverrides = cloneDeep(this.state.dimensionOverrides)
    let descriptionOverrides = cloneDeep(this.state.descriptionOverrides)
    treeSelector(selectors, dimensionOverrides, descriptionOverrides, object, previousSelection)
    .then(function(selectorData){
      let design = cloneDeep(this.state.design)
      design.smartCode = selectorData.metaData.smartCode.join('-')
      design.dimensions = selectorData.dimensions?.dimensions
      design.pricing = formatPricing(selectorData.metaData, this.state.product.pricing_options, design.quantity)
      this.setState({
        selectors: selectorData.selectors,
        limits: selectorData.limits,
        metaData: selectorData.metaData,
        dimensions: selectorData.dimensions
      }, function(){
        let prevDetails = this.state.details
        let details = buildSayduckSelections(selectorData.selectors)
        delete details[this.state.product.configurator_id]
        this.setState({
          details: details,
          design: design
        }, function(){
          if(this.state.lightsOffTriggers.length > 0) {
            this.state.lightsOffTriggers.forEach((item, i) => {
              let itemIndex = parseInt(item.smart_code_position)
              if(selectorData.metaData.smartCode[itemIndex] === item.smart_code){
                if(this.state.lightsOn){
                  this.toggleLights(false)
                }
              }
            })
          }
          if(this.props.updateMetaData){
            this.props.updateMetaData(selectorData.metaData)
          }
          let updateDetails = []
          for(var key in details) {
            if(details[key] !== prevDetails[key]){
              updateDetails[key] = details[key]
              api?.configurator.setActiveByUuid(details[key])
            }
          }
          if(selectorData.resetCamera){
            setTimeout(()=>{
              resetCamera(api)
            },500)
          }
        }.bind(this))
      }.bind(this))
    }.bind(this))
  }

  updateDetails = (details, metaData, dimensions, selectors) => {
    let lightsOn = this.state.lightsOn
    let newState = {
      metaData: metaData,
      dimensions: dimensions,
      selectors: selectors,
      lightsOn: lightsOn
    }
    let lightsOffTriggered = false
    if(this.state.lightsOffTriggers.length > 0) {
      this.state.lightsOffTriggers.forEach((item, i) => {
        let itemIndex = parseInt(item.smart_code_position)
        if(metaData.smartCode[itemIndex] === item.smart_code){
          newState.lightsOn = false
          newState.disableLight = true
          lightsOffTriggered = true
        }
      })
    }
    if(!lightsOffTriggered){
      newState.disableLight = false
    }
    if(newState.lightsOn){
      if(this.state.shadowSelectors){
        let shadow_id = this.state.shadowSelectors.id
        details[shadow_id] = this.state.shadowSelectors.shadows[0].configurator_id
        newState.details = details
      }
    }else{
      if(this.state.shadowSelectors){
        let shadow_id = this.state.shadowSelectors.id
        details[shadow_id] = this.state.shadowSelectors.off_id
        newState.details = details
      }
    }
    newState.details = details
    if(this.props.updateMetaData){
      this.props.updateMetaData(metaData)
    }
    this.setState(newState, function(){

    })
    if(isBrowser){
      for (var key in details) {
        try{
          api.configurator.setActiveByUuid(details[key])
        }catch(err){
          console.log(err)
        }
      }
      for (var key in details) {
        try{
          api.configurator.setActiveByUuid(details[key])
        }catch(err){
          console.log(err)
        }
      }
    }
  }

  takePhoto =  async () => {
    this.handleSetLoading(true)
    let fileName = this.state.product?.product_name.toLowerCase().replaceAll(' ','-')
    if(isBrowser){
      let photo = await takePhoto(api, true)
      const link = document.createElement('a');
      link.download = fileName + '.png';
      link.href = photo?.dataUrl
      link.click()
      this.handleSetLoading(false)
    }
  }

  handleSetLights = (lights) => {
    this.setState({
      lightsOn: lights
    }, function(){
      this.updateDetails(this.state.details, this.state.metaData, this.state.dimensions, this.state.selectors)
    })
  }

  getCanvasImage = () => {
    let canvasarray = document.getElementsByClassName('sayduck-3d-viewer-109 sayduck-3d-viewer-112')
    let tempImage = null
    if(canvasarray){
      if(canvasarray.length >= 1){
        tempImage = canvasarray[0].children[0].toDataURL()
      }
    }
    return tempImage
  }

  handleUpdateQuantity = (quantity) => {
    let design = {...this.state.design}
    design.quantity = quantity
    this.setState({
      design: design
    }, function(){
      if(this.props.updateQuantity){
        this.props.updateQuantity(quantity)
      }
    })
  }

  handleSetLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  handleAddToProjectSuccess = (projects) => {
    let projectNames = []
    projects.forEach((item, i) => {
      projectNames.push(item.name)
    })
    this.setState({
      projectNames: projectNames,
      visiblePanel: 'success'
    })
  }

  selectProject = (projectId) => {
    let projects = this.state.myProjects
    projects.forEach((item, i) => {
      item.selected = false
    })
    let projectSelected = projects.find((object)=>{
      return object._id === projectId
    })
    projectSelected.selected = true
    this.setState({
      myProjects: projects
    })
  }

  addToProject = async () => {
    if(isBrowser){
      this.handleSetLoading(true)
      let photo = await takePhoto(api)
      let selectedProject = this.state.myProjects.find((project)=>{
        return project.selected
      })
      let newDesign = {...this.state.design}
      newDesign.smartCode = this.state.metaData.smartCode.join('-')
      newDesign.smartDescription = this.state.metaData.smartDescription
      newDesign.dimensions = this.state.metaData.dimensions
      delete newDesign._id
      delete newDesign.created
      delete newDesign.updated
      newDesign.file = photo.dataUrl
      newDesign.project = selectedProject._id
      createDesign(newDesign)
      .then((design)=>{
        this.handleSetLoading(false)
        this.setState({
          showAddToProjectSuccess: true,
          showProjectBrowser: false,
          showNewProject: false
        })
      })
      .catch(err=>{
        console.log(err)
        this.setError(err)
      })
    }
  }

  launchWebAR = () => {
    if(isBrowser){
      this.handleSetLoading(true)
      if(api){
        if(isMobile){
          this.setState({
            ArMsg: true
          })
          this.handleSetLoading(false)
          api.scene.launchWebAr()
        }else{
          api.scene.getQrCodeUrl()
          .then(url => {
            this.setState({
              showQRCode: true,
              qrText: url
            })
            this.handleSetLoading(false)
          })
        }
        
      }else{
        var element = document.getElementsByClassName('sayduck-3d-viewer-164')[0]
        element.click()
        this.handleSetLoading(false)
        if(isMobile){
          this.setState({
            ArMsg: true
          })
        }
      }
    }
  }

  render() {
    let context = null
    let shadowSelectors = null
    if(this.state.product){
      context = {
        id: this.props.context.id,
        frontmatter: this.state.product,
        product: this.state.product,
        selectors: this.state.selectors,
        hardware_kits: this.props.context.hardware_kits,
        swatches: this.props.context.swatches
      }
      if(context.product.shadow_id){
        shadowSelectors  = {
          id: context.product.shadow_id,
          off_id: context.product?.shadow_off_id,
          shadows: context.product.shadows
        }
      }
    }

    let lightToggleButton = null
    if(this.state.product.uses_light){
      lightToggleButton = <LightToggle
      lightsOn={this.state.lightsOn}
      api={api}
      setLights={
        this.toggleLights
      }/>
    }
    let selectedProject = this.state.myProjects.find((project)=>{
      return project.selected
    })
    let menuItems = <>
      <IconButton title="Specification Sheet" onClick={this.downloadPDF}>
        <img src={DownloadIconWhite} alt='Specification Sheet' />
      </IconButton>
      <IconButton onClick={this.takePhoto} title="Take a Screenshot">
        <img src={CameraIcon} alt='Take a Screenshot' />
      </IconButton>
      {!this.props.hideLogin &&
        <>
        <IconButton onClick={()=>{
          if(this.state?.userInfo){
            this.setState({
              showProjectBrowser: true
            })
          }else{
            this.setState({
              showAuthPanel: true,
              defaultAuthView: 'login'
            })
          }
        }} title="Add to Project">
        <AddIcon />
        </IconButton>
        <IconButton onClick={()=>{navigate('/dashboard',true)}} title="Go to Dashboard">
          <LoginIcon />
        </IconButton>
        </>
      }
      <Button onClick={(e)=>{
        this.setState({browserOpen: true})
      }} variant="contained" title="All Products">
        All Products
      </Button>
    </>
    return(
      <Layout 
      titleText={this.state?.design?.name}
      className='no-pad'
      showMenu={false}
      toolFunctions={menuItems}
      hideAppBar={true}>
      {this.state.loading &&
        <LoadingBox />
      }
      {context &&
        <section className='embedded-view-wrapper'>
          <Viewer
            id={context.id}
            product={context.product}
            selectors={context.selectors}
            lightsOn={this.state.lightsOn}
            usesLight={this.state.usesLight}
            setLights={this.handleSetLights}
            shadowSelectors={shadowSelectors}
            details={this.state.details}
            api={api}
            style={{
              height: this.state.viewerHeight
            }}
          >
            <div className='viewer-buttons'>
              {lightToggleButton}
              <IconButton onClick={this.launchWebAR}>
                <img src={ArIcon} alt='launch web ar' />
              </IconButton>
              <FullScreenButton toggleFullScreen={()=>{
                this.setState({
                  isFullScreen: !this.state.isFullScreen
                })
              }} />

            </div>
          </Viewer>
          <ToolPanel title={this.state?.design?.name}
            hideQuantity={this.props.hideAddNewButton}
            quantity={this.state?.design?.quantity}
            selectors={context.selectors}
            limits={this.state?.limits}
            customTextVals={this.state.customTextVals}
            swatches={context.swatches}
            swatchImages={this.props.data.allFile.edges}
            handleUpdateQuantity={this.handleUpdateQuantity}
            updateData={this.updateData}
            handleUpdateNotes={this.handleUpdateNotes}
            design={this.state.design}
            product={this.state.product}
            handleUpdateHeight={(height, toolPosition)=>{
              this.setState({
                viewerHeight: height,
                toolPosition: toolPosition
              })
            }}
            />
        </section>
      }

        <Dialog
          open={this.state.browserOpen}
          maxWidth={false}
          onClose={(e)=>{this.setState({
            browserOpen: false
          })}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          <IconButton onClick={()=>{
                this.setState({browserOpen: false})
              }}>
                <CloseIcon />
            </IconButton>
            <span>{'Product Library'}</span>
          </DialogTitle>
          <DialogContent sx={{display: 'flex', overflow: 'hidden'}}>
            <ProductsBrowser
              product={this.state.product}
              products={this.props.products}
              handleSetLoading={this.handleSetLoading}
              images={this.props.data.allFile.edges}
              productCategories={this.props.context.categories}
              closeBrowser={(e)=>{
                this.setState({browserOpen: false})
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=>{
                this.setState({browserOpen: null})
              }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Drawer
        className='product-browser-drawer'
        anchor={'left'}
        style={{
          width: '100%'
        }}
        open={this.state.showAuthPanel}>
          <AuthPanel
            handleSetLoading={this.handleSetLoading}
            closeDrawer={(e)=>{
              this.setState({showAuthPanel: false})
            }}
            defaultView={this.state.defaultAuthView}
            returnFromLogin={(userData)=>{
              this.getProjects()
              this.setState({
                successMsg: 'Login Successful',
                showAuthPanel: false,
                userInfo: userData
              })
            }}
            setSuccessMessage={(message)=>{
              this.setState({
                successMsg: message
              })
            }}
            setErrorMessage={(message)=>{
              this.setState({
                errMsg: message
              })
            }}
          />
        </Drawer>

      <Dialog
        open={this.state.showProjectBrowser}
        onClose={(e)=>{this.setState({
          showProjectBrowser: false
        })}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         <IconButton onClick={()=>{
              this.setState({showProjectBrowser: false})
            }}>
              <CloseIcon />
          </IconButton>
          <span>{'Select Project'}</span>
        </DialogTitle>
        <DialogContent>
          <ProjectBrowser
            projects={this.state.myProjects}
            selectProject={this.selectProject}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>{this.setState({
            showProjectBrowser: false,
            showNewProject: true
          })}} >New Project</Button>
          <Button variant="contained" onClick={(selectedProject ? this.addToProject : null)}>
            Add to Project
          </Button>
        </DialogActions>
      </Dialog>
      <ProjectFormNew 
      api={api}
      open={this.state.showNewProject}
      handleSetLoading={this.handleSetLoading}
      onClose={()=>{
        this.setState({showNewProject: false})
      }}
      handleCancel={()=>{
        this.setState({showNewProject: false})
      }}
      onComplete={(project)=>{
        this.setState({showNewProject: false})
        let myProjects = [...this.state.myProjects]
        project.selected = true
        myProjects.push(project)
        this.setState({
          myProjects: myProjects
        }, this.addToProject)
      }} 
      />
      <Snackbar open={(this.state.ArMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseArMessage}>
        <Alert elevation={6} variant="filled" severity="warning" onClose={this.handleCloseArMessage}>
          AR Model is downloading. Please wait...
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      <Dialog
        open={this.state.showAddToProjectSuccess}
        onClose={()=>{this.setState({showAddToProjectSuccess: false})}}>
        <DialogTitle>
          {'Add to Project Success'}
        </DialogTitle>
        <DialogContent>
          This product was successfully added to your project. You can view and manage this project in your dashboard.
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({showAddToProjectSuccess: false})}}>Close</Button>
          <Button variant="contained" onClick={()=>{navigate('/dashboard',true)}}>Go to Dashboard</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.showQRCode}
        maxWidth={'xs'}
        onClose={()=>{this.setState({showQRCode: false})}}>
        <DialogTitle>
          {'Scan the QR code with your mobile camera for augmented reality'}
        </DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem'
          }}>
          <QRCode value={this.state.qrText} renderAs='svg' size='300' />
          <p style={{textAlign: 'center'}}>To see the model in augmented reality you need an iOS or Android AR supported device.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({showQRCode: false})}}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={(this.state.timeoutMessage ? true : false)} onClose={this.handleCloseTimeoutMessage}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseTimeoutMessage} sx={{color: '#fff'}}>
          <span style={{color: '#fff'}}>
            Our 3D Viewer component is either experiencing an outage or longer than usual load times.
            Please visit our <a style={{color: '#fff'}} href='https://formssurfaces.instatus.com/' target="_blank" rel="noreferrer">status page</a> or contact us at <a style={{color:'#fff'}} href="mailto:support@forms-surfaces.com">support@forms-surfaces.com</a></span>
        </Alert>
      </Snackbar>
      </Layout>
    )
  }
}

export default View
