import React, { Component } from 'react'
import {
  Button,
  TextField,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { createProject } from '../../utils/project'
import { createDesign } from '../../utils/design'
import { getTaxRate } from '../../utils/q'
import { takePhoto } from '../../utils/sayduck'
const isBrowser = typeof window !== `undefined`

class FormNewProject extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return(
      <form
        className="entry-form"
        method="post"
        onSubmit={event => {
          this.props.handleSubmit(event)
        }}
        style={{
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'space-between',
         margin: '16px 0 0',
         flexGrow: 1
       }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          flexGrow: 1
        }}>
          <div className="required">
            <FormLabel>Project Name</FormLabel>
          <TextField
           required
           id="name"
           name="name"
           defaultValue=""
           placeholder="Untitled"
           variant="outlined"
           onChange={this.props.handleUpdate}
          />
          </div>
          <div className="required">
            <FormLabel>Project Zip/Postal Code</FormLabel>
          <TextField
          required
           id="zip"
           name="zip"
           defaultValue=""
           placeholder="00000"
           variant="outlined"
           onChange={this.props.handleUpdate}
           onBlur={this.props.handleZipUpdate}
           className='location-input'
          />
          </div>
        </div>
        {this.props.location &&
          <div>
            <p><strong>Project Location: </strong></p>
            <p>{this.props.location}</p>
          </div>
        }
      </form>
    )
  }
}


class ProjectFormNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      country: '',
      company: '',
      city: '',
      state: '',
      zip: '',
      addToProject: true,
      errMsg: '',
      disableSubmit: false,
      zipInfo: null
    }
  }

  componentDidMount(){

  }

  handleUpdate = (e) => {
    this.setState(
      {[e.target.name]: e.target.value, errMsg: ''}
    )
  }
  handleZipUpdate = e => {
    getTaxRate(e.target.value)
    .then(zipInfo=>{
      this.setState({
        zipInfo: zipInfo
      })
    })
  }
  handleSubmit = async e => {
    e.preventDefault()
    this.props.handleSetLoading(true)
    if(isBrowser){
      let project = {
        name: this.state.name,
        zip: this.state.zip
      }
      if(this.state.zipInfo){
        project.city = this.state.zipInfo.geoCity
        project.state = this.state.zipInfo.geoState
      }
      let data = await createProject(project)
      if(this.state.addToProject && this.props.showAddToProject){
        let smartCode = this.props.metaData.smartCode.join('-')
        let photo = await takePhoto(this.props.api)
        let design = {
          smartCode: smartCode,
          smartDescription: this.props.metaData.smartDescription,
          name: this.props.product.product_name,
          productClass: this.props.product.product_class,
          dimensions: this.props.metaData.dimensions,
          configuratorId: this.props.product.configurator_id,
          project: data._id,
          file: photo.dataUrl
        }
        createDesign(design)
        .then((designData) => {
          this.props.onComplete(data)
        })
        .catch((err) => {
          this.setState({
            errMsg: err.message
          })
        })
      }else{
        this.props.onComplete(data)
      }
    }
  }

  render() {
    let location = []
    if(this.state.zipInfo){
      location.push(this.state.zipInfo.geoCity)
      location.push(this.state.zipInfo.geoState)
    }
    return(
      <Dialog
      open={this.props.open}
      onClose={this.props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton onClick={this.props.onClose}>
            <CloseIcon />
        </IconButton>
        <span>{"Create a Project"}</span>
      </DialogTitle>
      <DialogContent>
      <FormNewProject 
        handleUpdate={this.handleUpdate}
        handleZipUpdate={this.handleZipUpdate}
        handleSubmit={this.handleSubmit}
        handleClose={this.props.handleCancel}
        location={location.join(', ')}
        onComplete={this.props.onComplete}
        showAddToProject={this.props.showAddToProject} />
        <DialogContentText id="alert-dialog-description">
        {this.state.errMsg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.handleCancel}>
        Cancel
        </Button>
        <Button onClick={this.handleSubmit} variant="contained">Create</Button>
      </DialogActions>
    </Dialog>
    )
  }
}

export default ProjectFormNew
