import axios from "axios"
import { Auth } from "aws-amplify"
const restPath = process.env.GATSBY_APIURL

export const getTerritoryManager = searchText => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function (session) {
          axios
            .post(
              restPath + "/tm-search",
              {
                searchText: searchText,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: session.idToken.jwtToken,
                },
              }
            )
            .then(function (data) {
              if (data.data) {
                if (data.data.errorMessage) {
                  let dataError = new Error(data.data.errorMessage)
                  reject(dataError)
                } else {
                  let findOP = data.data.find(item => {
                    return item.DivisionName === "Outdoor Product"
                  })
                  resolve(findOP)
                }
              } else {
                let dataError = new Error("Malformed Response")
                reject(dataError)
              }
            })
            .catch(function (err) {
              reject(err)
            })
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

const noTax = ["IA", "UT"]
const freightNotTaxable = [
  "AZ",
  "CA",
  "CO",
  "FL",
  "GA",
  "KS",
  "MN",
  "NC",
  "NE",
  "NV",
  "NY",
  "OH",
  "SC",
  "TN",
  "TX",
  "VA",
  "WA",
]

export const getTaxRate = searchText => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          restPath + "/tax-rate",
          {
            searchText: searchText,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (data) {
          let rate = {
            rate: 0,
            handlingTaxable: true,
            freightTaxable: true,
          }
          if (data?.data?.SalesTaxRate) {
            rate.rate = data.data.SalesTaxRate / 100
            rate.geoCity = data.data.ItemJurisdiction.split("/")[1]
            rate.geoState = data.data.ItemJurisdiction.split("/")[0]
            if (noTax.includes(rate.geoState)) {
              rate.handlingTaxable = false
              rate.freightTaxable = false
            }
            if (freightNotTaxable.includes(rate.geoState)) {
              rate.freightTaxable = false
            }
          }
          resolve(rate)
        })
        .catch(function (err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
