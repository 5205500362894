import React, { Component } from 'react'
import Seo from '../components/seo'
import SelectObject from '../components/select-object'
import {
  selectorsFromDetail
} from '../utils/sayduck'
import { Script } from 'gatsby'
const isBrowser = typeof window !== `undefined`
let api;

class QC extends Component {
  constructor(props) {
    super(props)
    var productId = 'abe4af30-f99a-0138-a40c-52cfa05c0056'
    if(this.props.id){
      productId = this.props.id
    }

    let pricingOptions = this.props.data.allMarkdownRemark.edges.find((product)=>{
      return product.node.frontmatter.configurator_id === this.props.id
    })
    let prices = []
    if(pricingOptions){
      if(pricingOptions.node){
        if(pricingOptions.node.frontmatter){
          prices = pricingOptions.node.frontmatter.pricing_options
        }
      }
    }
    this.state = {
      productId: productId,
      product: pricingOptions,
      options: [],
      flatOptions: [],
      labelSelect: [],
      renderLabels: [],
      metaDesc: [],
      customDesc: [],
      insertDesc: [],
      smartPart: [],
      limits: [],
      pricingOptions: prices,
      sayduckSelectors: ''
    }
    if (isBrowser) {
      // document.addEventListener("sayduck.configurator.selectedConfigurationUpdated", (e) => {
      //   let options = []
      //   let limits = []
      //   Object.entries(e.detail).forEach(([index, item]) => {
      //     item.id = index
      //     Object.entries(item.metadata).forEach((meta) => {
      //       let key = meta[0]
      //       let value = meta[1]
      //       if(value.trim()){
      //         if(key.startsWith('limit_')){
      //           let limit = {
      //             match: {
      //               value: item.id
      //             },
      //             optionsLabel : key.replace('limit_','').replaceAll('_',' ').toUpperCase(),
      //             values : value.split(',')
      //           }
      //           if(!item.limits){
      //             item.limits = []
      //           }
      //           item.limits.push(limit)
      //           // item.limit = limit
      //           limits.push(limit)
      //         }
      //       }
      //     });
      //     options.push(item)
      //   })
      //   let tree = []
      //   selectorsFromDetail(e.detail).then((sayduckSelectors)=>{
      //     let metaDesc = []
      //     let insertDesc = []
      //     let smartPart = []
      //     let labelSelect = []
      //     let flatOptions = []

      //     this.setState({
      //       options: tree,
      //       limits: limits,
      //       flatOptions: flatOptions,
      //       metaDesc: metaDesc,
      //       insertDesc: insertDesc,
      //       smartPart: smartPart,
      //       labelSelect: labelSelect,
      //       sayduckSelectors: sayduckSelectors
      //     }, function(){
      //     })
      //   })
      // }, false)
      window.addEventListener('sayduck.api-ready', (event) => {
        console.log('sayduck api ready')
        api = event.detail.instance
        let configurations = api.configurator.getConfigurations()
        console.log(configurations)
        let options = []
        let limits = []
        // Object.entries(configurations).forEach(([index, item]) => {
        //   item.id = index
        //   Object.entries(item.metadata).forEach((meta) => {
        //     if(meta?.userDictionary){
        //       let key = Object.values(meta?.userDictionary)[0]
        //       let value = Object.values(meta?.userDictionary)[1]
        //       if(value?.trim()){
        //         if(key.startsWith('limit_')){
        //           let limit = {
        //             match: {
        //               value: item.id
        //             },
        //             optionsLabel : key.replace('limit_','').replaceAll('_',' ').toUpperCase(),
        //             values : value.split(',')
        //           }
        //           if(!item.limits){
        //             item.limits = []
        //           }
        //           item.limits.push(limit)
        //           // item.limit = limit
        //           limits.push(limit)
        //         }
        //       }
        //     }
        //   });
        //   options.push(item)
        // })
        let tree = []
        // let tree = arrayToTree(options,{ id: 'id', parentId: 'parent', childrenField: 'children', dataField: null })
        selectorsFromDetail(configurations, true).then((sayduckSelectors)=>{
          let metaDesc = []
          let insertDesc = []
          let smartPart = []
          let labelSelect = []
          let flatOptions = []
          // tree.forEach((item, i) => {
          //   labelSelect[i] = []
          //   Object.entries(item.metadata).forEach(([key, value]) => {
          //     if(value.trim()){
          //       if(key === 'hide_select'){
          //         item.hide = true
          //       }else if(key === 'sort_override'){
          //         item.sortOverride = parseInt(value)
          //       }else if(key === 'show_colorpicker'){
          //         item.showColorPicker = parseInt(value)
          //       }
          //     }
          //   })
          //   this.getDetail(item, details, metaDesc, insertDesc, smartPart, labelSelect[i], flatOptions, null)
          // })
          this.setState({
            options: tree,
            limits: limits,
            flatOptions: flatOptions,
            metaDesc: metaDesc,
            insertDesc: insertDesc,
            smartPart: smartPart,
            labelSelect: labelSelect,
            sayduckSelectors: sayduckSelectors
          }, function(){
          })
        })
      });
    }
  }

  componentDidMount(){

  }

  hasLimits = (limits, option) => {
    return limits.filter(element=>element.match.value === option.selected)
  }

  applyLimit = (option, limit, flatOptions, smartPart, metaDesc) => {
    let limitOption = flatOptions.find(element=>element.optionsLabel === limit.optionsLabel)
    if(limitOption){
      let disabledCount = 0
      limitOption.children.forEach((item, i) => {
        let optionDisabled = true
        Object.entries(item.metadata).forEach(([key, value]) => {
          if(limit.values.includes(value)){
            optionDisabled = false
          }
        })
        if(optionDisabled){
          disabledCount++
        }
        limitOption.children[i].disabled = optionDisabled
      })
      if(disabledCount === limitOption.children.length){

        limitOption.omit = true
        limitOption.children.forEach((item, i) => {
          Object.entries(item.metadata).forEach(([key, value]) => {
            let valuesPlacement = key.split('_')
            if(valuesPlacement[0] === 'smartpart'){
              smartPart.splice(valuesPlacement[1],1)
            }else if(valuesPlacement[0] === 'smartdesc'){
              metaDesc.splice(valuesPlacement[1],1)
            }
          })
          // this.setState({
          //   metaDesc: metaDesc,
          //   smartPart: smartPart
          // })
        })
      }
    }
  }

  valueSelected = (flatOptions, value) => {
    return flatOptions.find(element=>element.selected === value)
  }

  searchTree = (element, id) => {
       if(element.id === id){
            return element
       }else if (element.children != null){
            var i
            var result = null
            for(i=0; result == null && i < element.children.length; i++){
                 result = this.searchTree(element.children[i], id)
            }
            return result
       }
       return null
  }

  getDetail = (item, detail, metaDesc, insertDesc, smartPart, labelSelect, flatOptions, parent, root) => {
    if(item.selected){
      var selectedItem = item.children.find(element=>element.id === item.selected)
      labelSelect.push(selectedItem.label)
      Object.entries(selectedItem.metadata).forEach(([key, value]) => {
        if(value.trim()){
          if(key === 'hide_select'){
            selectedItem.hide = true
          }else if(key === 'sort_override'){
            selectedItem.sortOverride = parseInt(value)
          }else if(key === 'show_colorpicker'){
            selectedItem.showColorPicker = parseInt(value)
          }else{
            let valuesPlacement = key.split('_')
            if(valuesPlacement[0] === 'smartpart'){
              smartPart[parseInt(valuesPlacement[1])] = value
              if(item.omit){
                smartPart[parseInt(valuesPlacement[1])] = ''
              }
            }else if(valuesPlacement[0] === 'smartdesclabel'){
              metaDesc[parseInt(valuesPlacement[1])].label = value
            }else if(valuesPlacement[0] === 'smartdesc'){
              let label = item.optionsLabel
              let newValue = value
              if(newValue.split(/\\n/).length > 1){
                newValue = newValue.split(/\\n/).map((item, i) => {
                    return <span key={i}>{item}<br/></span>
                })
              }
              metaDesc[parseInt(valuesPlacement[1])] = {label: label, value: newValue}
            }else if(valuesPlacement[0] === 'insertdesc'){
              insertDesc[parseInt(valuesPlacement[1])] = {index: parseInt(valuesPlacement[1]),label: item.optionsLabel, value: value}
            }else if(valuesPlacement[0] === 'insertdesclabel'){
              insertDesc[parseInt(valuesPlacement[1])].label = value
            }
          }
        }
      })
      detail[item.id] = item.selected
      if(parent){
        item.parent = parent
      }else{
        root = item
      }
      item.root = root
      flatOptions.push(item)
      if(selectedItem){
        this.getDetail(selectedItem, detail, metaDesc, insertDesc, smartPart, labelSelect, flatOptions, item, root)
      }
    }
  }

  labelSelector = (labelRoot, labelIndex, item) => {
    if(!item.updated){
      if(item.children.length){
        let labelSelected = item.children.find(element=>element.label === this.state.labelSelect[labelRoot][labelIndex])
        if(labelSelected){
          item.selected = labelSelected.id
          labelIndex++
          this.labelSelector(labelRoot, labelIndex, labelSelected)
        }else{

        }
      }
    }else{
      item.updated = false
      let labelSelected = item.children.find(element=>element.id === item.selected)
      labelIndex++
      this.labelSelector(labelRoot, labelIndex, labelSelected)
    }
  }

  updateData = (object, previousOption, root) => {
    let options = [...this.state.options]
    let rootItem = options.find(element=>element.id === root.id)
    let found = this.searchOptions(rootItem, object)
    if(found){
      found.selected = object.selected
      found.updated = true
      if(object.omit){
        found.omit = true
      }
    }

    let details = []
    let metaDesc = []
    let smartPart = []
    let labelSelect = []
    let flatOptions = []
    let customDesc = []
    let insertDesc = []
    if(object.customDesc){
      customDesc[object.customDesc.index] = object.customDesc.value
    }
    options.forEach((item, i) => {
      labelSelect[i] = []
      this.getDetail(item, details, metaDesc, insertDesc, smartPart, labelSelect[i], flatOptions, null)
    })

    options.forEach((item, i) => {
      this.labelSelector(i, 0, item)
    })
    flatOptions = []
    options.forEach((item, i) => {
      labelSelect[i] = []
      this.getDetail(item, details, metaDesc, insertDesc, smartPart, labelSelect[i], flatOptions, null)
    })

    this.setState({
      options: options,
      metaDesc: metaDesc,
      smartPart: smartPart,
      labelSelect: labelSelect,
      customDesc: customDesc
    }, function(){
      window.dispatchEvent(
        new CustomEvent(
          'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
        )
      )
    })
  }

  searchOptions = (tree, target) => {
    if (tree.id === target.id) {
      return tree
    }

    for (const child of tree.children) {
      const res = this.searchOptions(child, target)
      if (res) {
        return res
      }
    }
  }

  getPricing = () => {
    let smartParts = this.state.smartPart.filter(element=>element != null)
    let prices = []
    if(this.state?.pricingOptions){
      prices = this.state?.pricingOptions.filter((priceOption)=>{
        let pricematched = true
        if(priceOption.smart_codes_selected){
          priceOption.smart_codes_selected.forEach((item, i) => {
            if(smartParts[item.position] !== item.smart_code){
              pricematched = false
            }
          })
        }
        return pricematched
      })
    }

    return prices
  }


  render() {
    let selectObjects = null
    let {
      flatOptions,
      limits,
      smartPart,
      metaDesc,
      insertDesc,
      customDesc
    } = this.state
    flatOptions.forEach((item, i) => {
      item.order = i
      //Always float product to top
      if(item.optionsLabel === 'PRODUCT'){
        item.order = -1
      }
      if(item.hide){
        item.order = 20
      }
      if(item.sortOverride){
        item.order = item.sortOverride
      }
      let activeLimits = this.hasLimits(limits,item)
      if(activeLimits.length){
        item.limitActive = true
        activeLimits.forEach((limit) => {
          this.applyLimit(item, limit, flatOptions, smartPart, metaDesc)
        })

      }
    })

    flatOptions.sort((a,b)=>(a.order > b.order) ? 1 : -1)
    if(flatOptions.length){
      selectObjects = flatOptions.map(function(option, i){
        return(<SelectObject option={option} root={option.root} parent={option.parent} updateData={this.updateData} key={i} index={i} metaDesc={metaDesc} customDesc={customDesc} selected={true} />)
      }.bind(this))
    }


    let smartParts = smartPart.filter(element=>element != null)
    let smartPartNumber = smartParts.join('-')

    let metaDescription = metaDesc.map(function(desc,i){
      let value = desc.value
      if(customDesc[i]){
        let newValue = value.replace(/SPECIFY.*/gm,'')
        if(newValue.split(/\\n/).count > 1){
          newValue = value.split(/\\n/).map((item, i) => {
              return <span key={i}>{item}<br/></span>
          })
        }
        value = <>{newValue}<span>{customDesc[i]}</span></>
      }
      return(<div key={i}><span className="red bold">{desc.label}:</span> {value}</div>)
    })

    insertDesc.forEach((desc, i) => {
      let insertVal = <div key={i}><span className="red bold">{desc.label}:</span> {desc.value}</div>
      metaDescription.splice(desc.index, 0, insertVal)
    })

    let prices = this.getPricing()
    let invoiceTotal = 0.0
    let weightTotal = 0.0
    let invoiceRows = prices.map((option, i)=>{
      if(option?.net_price[0]){
        invoiceTotal += option.net_price[0]?.value
        weightTotal += (option.weight ? parseFloat(option.weight) : 0)
        return(<div className="row" key={i}>
          <div className="col-sm-2">
          {option.model}
          </div>
          <div className="col-sm-6">
          {option.description}
          </div>
          <div className="col-sm-2">
          {option.net_price[0].value} {option.net_price[0].currency}
          </div>
          <div className="col-sm-2">
          {option.weight} lbs.
          </div>
        </div>)
      }

    })
    return(
      <>
        <Seo title={this.state?.product?.name} />
        <div style={{
          width: '100%',
          height: '50vh'
        }}>
        <Script src="https://viewer.sayduck.com" type="module" async />
          { (true) &&
                <sayduck-viewer 
                product={this.props.id} 
                mode="configurator" 
                hide-picker  
                background='transparent'
                hide-branding="true"
                hide-product-info
                hide-qr-code
                hide-web-ar
                hide-embed
                hide-fullscreen
                hide-dimensions
                hide-photo-studio
                ></sayduck-viewer>
          }
          { (false) && 
                <div id="sayduck-3d-viewer-container"
                aria-hidden
                style={{
                  minHeight:'450px',
                  minWidth:'300px',
                  width:'100%',
                  height:'100%',
                  display: 'block',
                  maxHeight: '50vh'
                }}
                data-product-uuid={this.props.id}
                hide-branding="true"
                data-viewer-options={'{"appearance":{ "hide-branding": true, "hide-logo": true, "hide-embed": true, "enable-qr-code": true, "background": "transparent", "hide-dimensions": false, "show-dimensions-on-load": true, "hide-photo-studio": true  }}'}
                />
          }

        </div>
        <div className="row">
        <div className="col-sm-12" style={{
          textAlign: 'center',
          color: '#fff',
          backgroundColor: '#333',
          marginTop: '2rem'
        }}>
          <strong>Invoice</strong>
        </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          <div className="row" style={{
            backgroundColor: '#c0c0c0'
          }}>
            <div className="col-sm-2">
            <strong>Model</strong>
            </div>
            <div className="col-sm-6">
            <strong>Description</strong>
            </div>
            <div className="col-sm-2">
            <strong>Price</strong>
            </div>
            <div className="col-sm-2">
            <strong>Weight</strong>
            </div>
          </div>
          {invoiceRows}
          <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8" style={{
              textAlign: 'right'
            }}>
            <strong>SubTotal:</strong>
            </div>
            <div className="col-sm-2">
            <strong>{invoiceTotal} USD</strong>
            </div>
            <div className="col-sm-2">
            <strong>{weightTotal} lbs.</strong>
            </div>
          </div>
          </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form id="config-selector">
              {selectObjects}
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          <p>{smartPartNumber}</p>
          <div>{metaDescription}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          <textarea style={{
            width: '100%',
            minHeight: '60vh',
            fontFamily: 'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
            fontSize: '.7rem',
            lineHeight: '1.25'
          }} defaultValue={this.state.sayduckSelectors} />
          </div>
        </div>
      </>
    )
  }
}

export default QC
